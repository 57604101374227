<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.query.from_salary ? $router.push({ name: 'bonusses' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'benefits'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $route.params.id ? $t('salary.benefits.title_edit') : $t('salary.benefits.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.query.from_salary ? $router.push({ name: 'bonusses' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'benefits'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract-multiple v-if="!$route.params.id && !$route.params.contract_id" :contract="benefits" :validator="$v.benefits"/>
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.benefits.title') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.bonus_type') }}*</label>
							<!-- <div v-if="$v.benefits.selectedBonusId.$error" class="error-msg">{{ $t('validator.required') }}</div> -->
							<div class="create-new" @click="showModal('benefit', {from: 'benefits'})">{{ $t('nomenclature.benefits.add_benefit') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.selectedBonusId.$error}">
							<div class="icon-left"><icon-benefit /></div>
							<div class="icon-right" v-if="benefits.selectedBonusId != ''" @click.stop="benefits.selectedBonusId = '', benefits.name = '', benefits.selectedPercentage = '', benefits.value = ''"><icon-close class="icon-clear" /></div>
							<multiselect
								v-model="benefits.selectedBonusId"
								v-bind:class="{populate: benefits.selectedBonusId != ''}"
								class="select-tags-tbf"
								:options="bonusOptionsType"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
                                @input="populateBonusForm(benefits.selectedBonusId)"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.benefits.bonus_type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.name') }}*</label>
                            <div v-if="$v.benefits.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.name.$error}">
							<div class="icon-left"><icon-benefit /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.benefits.name-ph')" v-model="benefits.name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.start_date') }}*</label>
							<div v-if="$v.benefits.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(benefits.start_date).length" @click.stop="benefits.start_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="benefits.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(benefits.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.benefits.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.final_date') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(benefits.final_date).length" @click.stop="benefits.final_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="benefits.final_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(benefits.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.benefits.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
							<div v-if="$v.benefits.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.selectedPercentage.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<!-- <div class="icon-right" v-if="benefits.selectedPercentage != ''" @click.stop="benefits.selectedPercentage = ''"><icon-close class="icon-clear" /></div> -->
							<multiselect 
								v-model="benefits.selectedPercentage"
								v-bind:class="{populate: benefits.selectedPercentage != ''}"
								class="select-tags-tbf"
								:options="percentageOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
                                @input="benefits.value = ''"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.benefits.calcul-type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div v-if="benefits.selectedPercentage.id == 1" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.percentage') }}*</label>
							<div v-if="$v.benefits.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.value.$error}">
							<div class="icon-left"><icon-percentage /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.benefits.percentage-ph')" v-model="benefits.value">
						</div>
					</div>
                    <div v-if="benefits.selectedPercentage.id == 2" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
							<div v-if="$v.benefits.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.benefits.value.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<input type="number" class="input-text" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="benefits.value">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.query.from_salary ? $router.push({ name: 'bonusses' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'benefits'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-benefits-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContractMultiple from  './ChooseContractMultiple'
    import IconCalculator from '../../../../Icons/Calculator'
	import IconPercentage from '../../../../Icons/Percentage'
	import IconBenefit from '../../../../Icons/Benefit'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderBenefitsCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContractMultiple,
			IconCalculator,
			IconPercentage,
			IconBenefit,
			IconCalendar,
			IconClose,
			IconArrowBack,
			LoaderBenefitsCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
                bonusOptionsType: [],
                percentageOptions: [{id: 1, name: this.$t('salary.benefits.percentage'), value: 1}, {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}],
                benefits: {
					contractSelected: '',
					selectedBonusId: '',
                    name: '',
					start_date: {},
					final_date: {},
					selectedPercentage: {id: 1, name: this.$t('salary.benefits.percentage'), value: 1},
					value: ''
                }
			}
		},
        validations: {
            benefits: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
                selectedBonusId: { required },
                name: { required },
				start_date: { required },
                selectedPercentage: { required },
				value: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getBonuses();

			if(this.$route.params.id){
				await this.getBonusData()
			}
			this.$root.$on('addDropdownBenefitCreate', (data, type) => {
				this.bonusOptionsType.push(data)
				this.benefits.selectedBonusId = data
				this.populateBonusForm(this.benefits.selectedBonusId)
			})
		},
        methods: {
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
            async getBonuses() {
                await axios.get(`${this.$auth.user().instance.id}/bonuses`).then(({data}) => {
					this.bonusOptionsType = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

			async getBonusData() {
				await axios.get(`/contract-bonuses/${this.$route.params.id}`).then(({data}) => {
					this.benefits.selectedBonusId = this.bonusOptionsType.find(el => el.id == data.data.bonus_id)
                    this.benefits.name = data.data.bonus_name
					if(data.data.start_date){
						this.benefits.start_date = {
							selectedDate: data.data.start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.end_date){
						this.benefits.final_date = {
							selectedDate: data.data.end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
					this.benefits.selectedPercentage = this.percentageOptions.find(el => el.value == data.data.is_percentage)
					this.benefits.value = data.data.value
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			populateBonusForm(bonus) {
				this.benefits.name = bonus.name
				this.benefits.selectedPercentage = bonus.is_percentage ? this.percentageOptions[0] : this.percentageOptions[1]
				this.benefits.value = bonus.value
			},

			changeEndDate() {
				if(this.benefits.start_date.selectedDate > this.benefits.final_date.selectedDate) {
					this.benefits.final_date = {}
				}
				this.calendarConfigsEndData.limits.min = this.benefits.start_date.selectedDate
			},
			
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.start_date = Object.keys(this.benefits.start_date).length ? this.benefits.start_date.selectedDate : ''
					objData.end_date = Object.keys(this.benefits.final_date).length ? this.benefits.final_date.selectedDate : ''
					objData.bonus_id = this.benefits.selectedBonusId ? this.benefits.selectedBonusId.id : ''
					objData.name = this.benefits.name
					objData.is_percentage = this.benefits.selectedPercentage ? this.benefits.selectedPercentage.value : ''
					objData.value = this.benefits.value
					
					if(this.$route.params.contract_id) {
						if(type == 'create'){
						this.createBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}else if(type == 'update'){
							this.updateBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}
					} else {
						var selectedContracts = this.benefits.contractSelected.length ? this.benefits.contractSelected.map(function(a) {return a.id;}) : ''
						if(selectedContracts.length) { objData.contract_ids = selectedContracts }
						this.createSalaryBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createSalaryBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$auth.user().instance.id}/contract-bonuses/store/multiple`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'bonusses' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			createBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$route.params.contract_id ? this.$route.params.contract_id : this.benefits.contractSelected.id}/contract-bonuses/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id ? this.$route.params.contract_id : this.benefits.contractSelected.id}, query: {tab: 'benefits'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			updateBenefits(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/contract-bonuses/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'bonusses' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'benefits'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>