<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.contracts.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.contracts.choose_contract') }}*</label>
					<div v-if="validator.contractSelected.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.contractSelected.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="contract.contractSelected != ''" @click.stop="contract.contractSelected = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="contract.contractSelected"
                        class="hide-tags  multiselect_checkbox"
						v-bind:class="{populate: contract.contractSelected != ''}"
						:options="contracts"
						:allow-empty="false"
						:show-labels="false"
                        :multiple="true" 
						:close-on-select="false"
						track-by="id"
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.contracts.choose_contract-ph') }}
							</span>
						</template>
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                            <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="checkbox">
                                <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                            </div>
                        </template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconContract from '../../../../Icons/Contract'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconContract,
			IconClose
		},
		props: {
            contract: Object,
			validator: Object
		},
		data() {
			return {
				loaded: false,
				contracts: []
			}
		},
		async mounted(){
            this.getContracts()
		},

        methods: {
            getContracts() {
                axios.get(this.$auth.user().instance.id + '/contracts', {params: {compact: true}})
        		.then(({data}) => {
        			if(data.data.length){
        			    this.contracts = data.data.map(el => {
                            return {name: el.first_name + ' ' + el.last_name + ' - ' + el.marca, id: el.id}
                        })
                    }
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
                }).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },
        }
	}
</script>